// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * @license
 * MyFonts Webfont Build ID 3860883, 2020-01-28T14:54:54-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Recoleta-Regular by Latinotype
 * URL: https://www.myfonts.com/fonts/latinotype/recoleta/regular/
 * Copyright: Copyright &#x00A9; 2018 by Jorge Cisterna. All rights reserved.
 * Licensed pageviews: 10,000
 *
 *
 *
 * © 2020 MyFonts Inc
*/

@font-face {
  font-family: 'Recoleta-Regular';
  src: url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.woff2')
      format('woff2'),
    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.woff')
      format('woff'),
    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.ttf')
      format('truetype');
}
`, "",{"version":3,"sources":["webpack://./www/src/helpers/baseStyles/recoleta-regular.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;CAkBC;;AAED;EACE,+BAA+B;EAC/B;;;;;wBAKsB;AACxB","sourcesContent":["/**\n * @license\n * MyFonts Webfont Build ID 3860883, 2020-01-28T14:54:54-0500\n *\n * The fonts listed in this notice are subject to the End User License\n * Agreement(s) entered into by the website owner. All other parties are\n * explicitly restricted from using the Licensed Webfonts(s).\n *\n * You may obtain a valid license at the URLs below.\n *\n * Webfont: Recoleta-Regular by Latinotype\n * URL: https://www.myfonts.com/fonts/latinotype/recoleta/regular/\n * Copyright: Copyright &#x00A9; 2018 by Jorge Cisterna. All rights reserved.\n * Licensed pageviews: 10,000\n *\n *\n *\n * © 2020 MyFonts Inc\n*/\n\n@font-face {\n  font-family: 'Recoleta-Regular';\n  src: url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.woff2')\n      format('woff2'),\n    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.woff')\n      format('woff'),\n    url('https://monami-www-fonts.s3.us-east-2.amazonaws.com/3AE993_0_0.ttf')\n      format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
