import React from 'react';
import { mount, redirect, route } from 'navi';
import LoginForm from 'components/LoginForm';
import paths from './paths';

const routes = mount({
  [paths.login]: route({ view: <LoginForm /> }), // Allow /login to be accessed normally
  '*': redirect(paths.login), // Redirect all other routes to /login
});

export default routes;
