import React from 'react';
import withApollo from 'helpers/withApollo';
import withStyles from 'react-jss';
import ContentWrapper from 'components/ContentWrapper';
import logo from 'assets/images/new-logo.svg';
import style from './styles';

interface DecommissionedProps {
  classes: any;
}

const Decommissioned: React.FC<DecommissionedProps> = ({ classes }) => {
  return (
    <ContentWrapper noPadding styles='justify-center flex'>
      <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto', height: '100%' }}>
        <div className='h-100 ph125 flex flex-column align-center justify-center mt'>
          <div className={classes.borderDivide}>
            <img className={`db margin-auto mb2 ${classes.logo}`} src={logo} alt='Mon Ami Logo' />
            <div className={`tc f3 ${classes.header}`}>This application is no longer supported.</div>
            <div className='tc f4'>Please use the new Volunteer Application at</div>
            <div className='tc f4'>
              <a href='https://volunteer.monami.io/' target='_blank' rel='noopener noreferrer'>
                https://volunteer.monami.io
              </a>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default withApollo(withStyles(style)(Decommissioned));
